import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const DashboardHeaderColumn = ({ imgUrl, imgAlt, headerTitle, bigText }) => {
  return (
    <>
      <Row>
        <Col className="image-wrapper">
          <div className="dh-header-img">
            <img src={process.env.PUBLIC_URL + imgUrl} alt={imgAlt} />
          </div>
        </Col>
        <Col>
          <div className="dh-header-title">
            <p>{headerTitle}</p>
          </div>
          <div className="dh-total">{bigText}</div>
          <div className="dh-stat">
            <p className="dh-stat-text">
              <span className="dh-stat-number"> &#x2191; 13% </span>this month
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DashboardHeaderColumn;
