import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import { Link, useLocation } from 'react-router-dom';
import './SideNav.css';
import { MdOutlineDashboard } from 'react-icons/md';
import { IoIosPeople, IoIosCreate, IoIosEye, IoIosHome } from 'react-icons/io';
import { MdOutlineBrowseGallery } from 'react-icons/md';
import { IoCreateOutline } from 'react-icons/io5';
import { MdOutlineGroups } from 'react-icons/md';

const SideNav = () => {
  const location = useLocation(); // Get the current location
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const isActive = (path) => {
    // Check if the current path includes the specified path
    return location.pathname.includes(path);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 950);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navLinks = (
    <Nav className={isMobile ? 'flex-row bottom-nav' : 'flex-column'}>
      <Nav.Link
        as={Link}
        to="/dashboard"
        className={isActive('/dashboard') ? 'active' : ''}
      >
        <MdOutlineDashboard /> {isMobile ? null : 'Dashboard'}
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/browsegroups"
        className={isActive('/browsegroups') ? 'active' : ''}
      >
        <MdOutlineBrowseGallery />
        {isMobile ? null : 'Browse'}
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/creategroup"
        className={isActive('/creategroup') ? 'active' : ''}
      >
        <IoCreateOutline /> {isMobile ? null : 'Create Group'}
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/mygroups"
        className={isActive('/mygroups') ? 'active' : ''}
      >
        <MdOutlineGroups /> {isMobile ? null : 'My Groups'}
      </Nav.Link>
    </Nav>
  );

  return (
    <>
      {isMobile ? (
        <div className="bottom-nav fixed-bottom bg-light">{navLinks}</div>
      ) : (
        <Card className="asquad-sidebar">
          <Card.Body>{navLinks}</Card.Body>
        </Card>
      )}
    </>
  );
};

export default SideNav;
