// FloatingBackButton.js
import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import '../App.css'; // Assuming you keep the CSS in a separate file
import { IoArrowBackCircleSharp } from 'react-icons/io5';

const FloatingGoBackButton = ({ onClick }) => {
  const renderBackTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Go Back
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="right" overlay={renderBackTooltip}>
      <Button className="floating-back-btn" onClick={onClick}>
        <IoArrowBackCircleSharp />
      </Button>
    </OverlayTrigger>
  );
};

export default FloatingGoBackButton;
