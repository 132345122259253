import React, { useState, useContext } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';
import { UserContext } from '../components/UserContext';
import axios from 'axios';
import SideNav from '../components/SideNav';
import { IoCreateOutline, IoIosArrowRoundBack } from 'react-icons/io5';
import { Toast } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { IoMdInformationCircle } from 'react-icons/io';

const apiUrl = process.env.REACT_APP_API_URL;

const CreateGroup = ({
  onAddCategory,
  categories = [],
  availableCategories = [],
}) => {
  const navigate = useNavigate();
  const { userId } = useContext(UserContext);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [groupname, setGroupName] = useState('');
  const [description, setDescription] = useState('');
  const [aboutMembers, setAboutMembers] = useState('');
  const [isPrivate, setIsPrivate] = useState(true);
  const [emails, setEmails] = useState([]); // will hold { label, value }
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success'); // Can be 'success' or 'error'
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token'); //
    setIsSubmitting(true); // Disables form fields initially

    const group = {
      users: [userId],
      creatorId: userId,
      categories: selectedCategories,
      groupname,
      description,
      aboutMembers,
      isPrivate,
      invitees: emails.map((email) => email.value), // map to just email strings
    };

    try {
      const response = await axios.post(`${apiUrl}/groups`, group, {
        headers: {
          'x-auth-token': token,
        },
      });
      setToastMessage('Group created successfully!');
      setToastType('success');
      setShowToast(true);
      setTimeout(() => {
        setIsSubmitting(false); // Re-enables form fields
        navigate('/mygroups'); // Adjust this path as needed
      }, 3000);
    } catch (error) {
      console.log(error);
      let message = 'An error occurred';
      if (error.response.data.error.includes('only create')) {
        const maxGroups = error.response.data.error.match(/\d+/)[0];
        message = `Sorry, you've reached the maximum limit of ${maxGroups} groups you can create. Please upgrade your plan :) `;
      }

      setToastMessage(error.response?.data?.msg || 'Failed to create group');
      // setToastType("error");
      // setShowToast(true);

      setErrorMessage(message);
      setShowErrorModal(true);
      setIsSubmitting(false);
    }
  };

  const ForwardRefIcon = React.forwardRef((props, ref) => (
    <IoMdInformationCircle ref={ref} {...props} />
  ));

  return (
    <Row>
      <Col md={2} className="sidebar">
        <SideNav />
      </Col>
      <Col>
        <Container className="pt-5">
          <h3 style={{ textAlign: 'center' }}>
            <IoCreateOutline /> Create Group
          </h3>
          <div className="create-group-wrapper">
            <div className="create-group-box">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control
                    value={groupname}
                    onChange={(e) => setGroupName(e.target.value)}
                    type="text"
                    placeholder="Enter Group name"
                    required
                    disabled={isSubmitting}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Categories</Form.Label>
                  <CreatableSelect
                    isMulti
                    onCreateOption={(label) => {
                      const newCategory = { id: uuidV4(), label };
                      setSelectedCategories((prev) => [...prev, newCategory]);
                    }}
                    value={selectedCategories.map((category) => ({
                      label: category.label,
                      value: category.id,
                    }))}
                    options={availableCategories.map((category) => ({
                      label: category.label,
                      value: category.id,
                    }))}
                    onChange={(categories) => {
                      setSelectedCategories(
                        categories.map((category) => ({
                          label: category.label,
                          id: category.value,
                        })),
                      );
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    required
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows={3}
                    disabled={isSubmitting}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>About members</Form.Label>
                  <Form.Control
                    as="textarea"
                    required
                    value={aboutMembers}
                    onChange={(e) => setAboutMembers(e.target.value)}
                    rows={2}
                    disabled={isSubmitting}
                  />
                </Form.Group>
                {/* <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Make group private"
                checked={isPrivate}
                onChange={(e) => setIsPrivate(e.target.checked)}
              />
            </Form.Group> */}

                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label={
                      <div
                        className="label-wrapper"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        Make group private
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip
                              style={{ zIndex: 2222 }}
                              id="button-tooltip"
                            >
                              Private groups are invite-only. Unchecking this
                              makes the group public, allowing open access
                            </Tooltip>
                          }
                        >
                          <div style={{ cursor: 'pointer', marginLeft: '5px' }}>
                            {' '}
                            {/* Ensure there is space between the text and icon */}
                            <IoMdInformationCircle />
                          </div>
                        </OverlayTrigger>
                      </div>
                    }
                    checked={isPrivate}
                    onChange={(e) => setIsPrivate(e.target.checked)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Invite Members (Emails)</Form.Label>
                  <CreatableSelect
                    isMulti
                    placeholder="Enter email addresses..."
                    onChange={(newEmails) => setEmails(newEmails)}
                    value={emails}
                    onCreateOption={(email) =>
                      setEmails([...emails, { label: email, value: email }])
                    }
                  />
                </Form.Group>

                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Link to="..">
                    <Button className="create-group" variant="primary">
                      Cancel
                    </Button>
                  </Link>
                  <Button
                    className="create-group"
                    variant="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>

          {/* <ToastContainer position="top-end" className="p-3">
  <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg={toastType}>
    <Toast.Header>
      <strong className="me-auto">Notification</strong>
    </Toast.Header>
    <Toast.Body>{toastMessage}</Toast.Body>
  </Toast>
</ToastContainer> */}

          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
            bg={toastType === 'success' ? 'success' : 'danger'}
            style={{
              position: 'fixed',
              bottom: 70,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 9999,
              color: 'white ',
            }}
          >
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>

          <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowErrorModal(false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </Col>
    </Row>
  );
};

export default CreateGroup;
