import React, { Component, useEffect, useState, useContext } from 'react';
import {
  Container,
  Form,
  Stack,
  Button,
  FloatingLabel,
  FormControl,
} from 'react-bootstrap';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../components/UserContext';
import Spinner from 'react-bootstrap/Spinner';
import { Watch } from 'react-loader-spinner';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import './MyGroup.css';
import { Toast, ToastContainer } from 'react-bootstrap';
import SideNav from '../components/SideNav';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const NewMyGroup = () => {
  const { userId } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const { groupId } = useParams();
  const [creatorId, setCreatorId] = useState();
  const navigate = useNavigate();
  const [groupDetails, setGroupDetails] = useState();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [emailInput, setEmailInput] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const [showToast, setShowToast] = useState(false);
  const handleShowToast = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  useEffect(() => {
    // setLoading(true);
    const token = localStorage.getItem('token'); //
    const isLoggedIn = Boolean(localStorage.getItem('token'));

    const fetchData = async () => {
      try {
        if (groupId && isLoggedIn) {
          // Check if adminId is defined
          const response = await axios.get(
            `${apiUrl}/groups/${groupId}?includeUserNames=true&includeCreatorName=true`,
            {
              headers: {
                'x-auth-token': token,
              },
            },
          );

          const group = response.data;
          console.log(response.data);
          setGroupDetails(group);
          console.log('userId, ', userId);
          console.log('creatorId ', response.data.creatorId);
          // console.log(groupDetails);

          setCreatorId(response.data.creatorId);
        } else {
          navigate('/signup');
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // const deleteGroup = async (e, groupId, userId) => {
  //   e.preventDefault();
  //   const token = localStorage.getItem("token");
  //   try {
  //     const res = await axios.post(
  //       `${apiUrl}/groups/delete`,
  //       {
  //         groupId,
  //         userId,
  //       },
  //       {
  //         headers: {
  //           "x-auth-token": token,
  //         },
  //       }
  //     );
  //     navigate("/userwelcome");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const deleteGroup = async (e, groupId, userId) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to delete this group?')) {
      const token = localStorage.getItem('token');
      // try {
      //   await axios.post(`${apiUrl}/groups/delete`, {
      //     headers: { "x-auth-token": token },

      //   });
      //   window.alert("Successfully deleted the group.");
      //   navigate("/userwelcome"); // Navigate to welcome page after deletion
      // } catch (error) {
      //   console.error("Failed to delete the group:", error);
      //   window.alert("Failed to delete the group.");
      // }

      try {
        const res = await axios.post(
          `${apiUrl}/groups/delete`,
          {
            groupId,
            userId,
          },
          {
            headers: {
              'x-auth-token': token,
            },
          },
        );
        window.alert('Successfully deleted the group.');
        navigate('/userwelcome');
      } catch (error) {
        console.error(error);
        window.alert('Failed to delete the group.');
      }
    }
  };

  const navigateToCreate = () => {
    // 👇️ navigate to /contacts
    navigate('groupcycle');
  };

  const addEmailToList = () => {
    if (emailInput.trim() !== '') {
      setEmailList((prevList) => [...prevList, emailInput.trim()]);
      setEmailInput('');
    }
  };

  const removeEmailFromList = (index) => {
    setEmailList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleSendInvites = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(
        `${apiUrl}/groups/send-invite`,
        {
          groupname: groupDetails.groupname,
          invitees: emailList,
        },
        {
          headers: {
            'x-auth-token': token,
          },
        },
      );
      handleShowToast('Invitations sent successfully!', 'success');
      setShowInviteModal(false);
      setEmailList([]);
    } catch (error) {
      console.error(error);
      handleShowToast('Failed to send invitations.', 'danger');
    }
  };

  const navigateToCurrentCycles = () => {
    // 👇️ navigate to /contacts
    navigate('groupcycleoverview');
  };
  const navigatetoAddMember = () => {
    navigate('addmember');
  };
  return (
    <>
      <Row>
        <Col md={2} className="sidebar">
          <SideNav />
        </Col>
        <Col md={10}>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-75">
              <div className="loading-section">
                <Watch
                  visible={true}
                  height="40"
                  width="40"
                  radius="48"
                  color="#4fa94d"
                  ariaLabel="watch-loading"
                  wrapperStyle={{}}
                  wrapperClass="watch-load"
                />
                <p className="loading-text">
                  "The two most powerful warriors are patience and time." – Leo
                  Tolstoy
                </p>
              </div>
            </div>
          ) : (
            <div className="wrapper-group-detail">
              <div className="wrapper-box">
                <Row className="fs-group">
                  <Col md={8} className="first-section-group">
                    <div className="content-group-title">
                      <p>{groupDetails.groupname}</p>
                    </div>
                    <div className="content-group-description">
                      <p>{groupDetails.description}</p>
                    </div>
                  </Col>
                  <Col md={4} className="control-section-group">
                    <div className="button-section">
                      <div
                        onClick={navigateToCreate}
                        style={{ cursor: 'pointer' }}
                        className="create-btn"
                      >
                        Create Cycle
                      </div>
                      <div
                        onClick={navigateToCurrentCycles}
                        className="current-cycles-btn"
                      >
                        Current Cycles
                      </div>
                      <div
                        onClick={() => setShowInviteModal(true)}
                        className="add-members-btn"
                      >
                        Add Members
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="grey-line"></div>
                <div className="member-details">
                  <div className="creator-details">
                    <p className="creator-title">Creator </p>
                    <p className="creator-icon">
                      {groupDetails.creatorId.name.charAt(0).toUpperCase()}
                    </p>
                    <p className="creator-name">{groupDetails.creatorName}</p>
                  </div>
                  <div className="member-names">
                    <p className="member-title">
                      Members({groupDetails.users.length}){' '}
                    </p>
                    <p className="members-icon-list">
                      {groupDetails.users.map((e) => (
                        <p className="single-group-member-icon">
                          {e.name.charAt(0).toUpperCase()}
                        </p>
                      ))}
                    </p>
                  </div>
                </div>
                <div className="grey-line"></div>
                {groupDetails.aboutMembers ? (
                  <div className="about-section-group-single">
                    <div className="title-about-group-single">
                      <p>About our members</p>
                    </div>
                    <div className="about-group-single-desc">
                      {groupDetails.aboutMembers}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {/* <div className="about-section-group-single">
                  <div className="title-about-group-single">
                    <p>About our members</p>
                  </div>
                  <div className="about-group-single-desc">
                    Created by John Doe who is passionate about self growth. Our
                    members are a diverse and motivated group of individuals
                    committed to personal growth and continuou improvement. They
                    come from various backgrounds and professions, united by the
                    common goal of harnessing the power of small, incremental
                    changes to achieve big results.
                  </div>
                </div> */}
                {userId === groupDetails.creatorId._id ? (
                  <div
                    onClick={(e) => deleteGroup(e, groupId, userId)}
                    className="delete-section-group-single"
                  >
                    <div className="delete-bin">
                      <RiDeleteBin6Line />
                    </div>
                    <div className="delete-text">delete</div>
                  </div>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          )}
        </Col>
      </Row>

      <Modal show={showInviteModal} onHide={() => setShowInviteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Members</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="emailInput">
            <Form.Label>Enter email addresses</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  addEmailToList();
                }
              }}
            />
            <Button variant="primary" onClick={addEmailToList}>
              Add Email
            </Button>
          </Form.Group>
          <div className="mt-3">
            {emailList.map((email, index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center"
              >
                <span>{email}</span>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => removeEmailFromList(index)}
                >
                  Remove
                </Button>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowInviteModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSendInvites}>
            Send Invites
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          bg={toastType}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Notification</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default NewMyGroup;
