import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

import { UserContext } from './UserContext';
import './OurNavbar.css';
import {
  IoIosPeople,
  IoIosCreate,
  IoIosEye,
  IoIosHome,
  IoMdPerson,
} from 'react-icons/io'; // Example icons

function OurNavbar() {
  const { userId, setUserId, userName, currentSubscription } =
    useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  // useEffect(() => {
  //   // Log the currentSubscription value whenever it changes
  //   console.log("Current subscription:", currentSubscription);
  // }, [currentSubscription]);
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    setUserId(null);
    navigate('/login');
    setExpanded(false);
  };

  const renderAuthLinks = () => {
    {
    }
    if (userId) {
      return (
        <>
          {/* <Nav.Link
            as={Link}
            to="/plans"
            className={location.pathname === "/plans" ? 'active' : ''}
          >
            {currentSubscription && currentSubscription === "Premium" ? "Manage Subscription" : "Upgrade Plan"}
          </Nav.Link>
          */}
          <NavDropdown
            title={
              <span>
                <IoMdPerson /> {userName}
              </span>
            }
            id="basic-nav-dropdown"
            align="end"
          >
            <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
            {currentSubscription ? (
              <NavDropdown.Item
                as={Link}
                to="/plans"
                className={location.pathname === '/plans' ? 'active' : ''}
              >
                {currentSubscription?.data?.plan?.plan == 'Premium'
                  ? 'Premium'
                  : 'Free'}
              </NavDropdown.Item>
            ) : (
              <NavDropdown.Item>Loading...</NavDropdown.Item>
            )}
          </NavDropdown>
        </>
      );
    } else {
      return (
        <>
          <Nav.Link
            as={Link}
            to={'/signup'}
            className={location.pathname === '/signup' ? 'active' : ''}
          >
            Signup
          </Nav.Link>
          <Nav.Link
            as={Link}
            to={'/login'}
            className={location.pathname === '/login' ? 'active' : ''}
          >
            Login
          </Nav.Link>
        </>
      );
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expanded={expanded}
      onToggle={(expanded) => setExpanded(expanded)}
      expand="lg"
      bg="light"
      variant="light"
    >
      <Container className="custom-navbar">
        <Navbar.Brand as={Link} to="/" className="custom-navbar-brand">
          <img
            src={process.env.PUBLIC_URL + '/img/asquad-logo-v3.png'}
            alt="Logo"
            style={{ cursor: 'pointer', height: '100%' }} // Adjust height as needed
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* Add other navigation links here if necessary */}
          </Nav>
          <Nav>{renderAuthLinks()}</Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default OurNavbar;
