import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const SubscribeSuccess = ({ show, onHide, onContinue }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Subscription Successful</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Your subscription has been successfully processed!</p>
        <Button onClick={onContinue}>Continue</Button>
      </Modal.Body>
    </Modal>
  );
};

export default SubscribeSuccess;
