import React from 'react';
import Row from 'react-bootstrap/Row';
import { PiBrowsersDuotone } from 'react-icons/pi';
import { IoMdPeople } from 'react-icons/io';
import { GoPeople } from 'react-icons/go';
import { BsPeople } from 'react-icons/bs';
import { GrPowerCycle } from 'react-icons/gr';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { RxArrowRight } from 'react-icons/rx';
import Col from 'react-bootstrap/Col';

const BoxGroup = ({
  groupname,
  description,
  numberOfMembers,
  numberOfCycles,
  groupId,
}) => {
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="group-card">
          <div className="header-wrapper">
            <div
              className="header-group-title"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {groupname}
            </div>
          </div>
          <div className="description-section">
            <div className="description-content">
              {truncateText(description, 40)}
            </div>
          </div>

          <div className="group-overview-section">
            <Row>
              <Col>
                <Row className="member-ov-section">
                  <Col className="member-icon">
                    <BsPeople />
                  </Col>
                  <Col className="member-detail">
                    <div className="member-no">{numberOfMembers}</div>
                    <div className="text-member">members</div>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="member-ov-section">
                  <Col className="member-icon">
                    <GrPowerCycle />
                  </Col>
                  <Col className="member-detail">
                    <div className="member-no">{numberOfCycles}</div>
                    <div className="text-member">cycles</div>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Link to={groupId}>
                  <Button className="view-details" variant="primary">
                    View <RxArrowRight />
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoxGroup;
