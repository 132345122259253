import React, { Component, useEffect, useState, useContext } from 'react';
import { Container, Form, Button, Badge } from 'react-bootstrap';
import { UserContext } from '../components/UserContext';
import { Watch } from 'react-loader-spinner';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import {
  IoIosPeople,
  IoIosCreate,
  IoIosEye,
  IoIosHome,
  IoMdPerson,
} from 'react-icons/io';

import axios from 'axios';
import SideNav from '../components/SideNav';

const apiUrl = process.env.REACT_APP_API_URL;

const GroupDetails = () => {
  const { userId } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const { groupId } = useParams();
  const navigate = useNavigate();
  const [groupDetails, setGroupDetails] = useState();
  const [isMember, setIsMember] = useState(false);
  const [groupUsers, setGroupUsers] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);

  function checkIsMember(userId, groupDetails) {
    console.log('CHECK ISMEMBER');
    console.log(JSON.stringify(groupDetails));
    if (
      groupDetails &&
      groupDetails.users &&
      groupDetails.users.includes(userId)
    ) {
      setIsMember(true);
    } else {
      setIsMember(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem('token'); //
    const isLoggedIn = Boolean(localStorage.getItem('token'));

    const fetchData = async () => {
      try {
        if (groupId && isLoggedIn) {
          // Check if adminId is defined
          const response = await axios.get(`${apiUrl}/groups/${groupId}`, {
            headers: {
              'x-auth-token': token,
            },
          });

          const group = response.data;

          setGroupDetails(group);

          checkIsMember(userId, response.data);

          // setGroupDetails(group);
          // console.log(groupDetails);
        } else {
          navigate('/signup');
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [groupId, isMember]);

  const navigateToGroup = (e, groupId) => {
    navigate(`/mygroups/${groupId}`);
  };

  const handleJoinLeaveGroup = async (e, userId, groupId, action) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    setLoading(true);
    if (action === 'JOIN') {
      try {
        const res = await axios.put(
          `${apiUrl}/groups/${groupId}/join`,
          { userId },
          {
            headers: {
              'x-auth-token': token,
            },
          },
        );
        const updatedGroup = res.data;

        setGroupDetails(updatedGroup);
        setGroupUsers(res.data.users);
        setIsMember(true);
        //checkIsMember(userId, res.data);
      } catch (error) {
        let message = 'An error occurred';
        if (error.response.data.error.includes('only join a maximum')) {
          const maxGroups = error.response.data.error.match(/\d+/)[0];
          message = `Sorry, you've reached the maximum limit of ${maxGroups} groups you can join. Please upgrade your plan :) `;
        }
        //  console.log(message);
        setErrorMessage(message);
        setShowErrorModal(true);
      } finally {
        if (groupDetails) {
          setLoading(false);
        }
      }
    } else if (action == 'LEAVE') {
      try {
        const res = await axios.put(
          `${apiUrl}/groups/${groupId}/leave`,
          { userId },
          {
            headers: {
              'x-auth-token': token,
            },
          },
        );
        const updatedGroup = res.data;

        setGroupDetails(updatedGroup);
        setGroupUsers(res.data.users);
        checkIsMember(userId, res.data);
      } catch (error) {
        console.log(error);
      } finally {
        if (groupDetails) {
          setLoading(false);
        }
      }
    }
  };
  return (
    <>
      <Row>
        <Col md={2} className="sidebar">
          <SideNav />
        </Col>
        <Col md={10}>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-75">
              <div className="loading-section">
                <Watch
                  visible={true}
                  height="40"
                  width="40"
                  radius="48"
                  color="#4fa94d"
                  ariaLabel="watch-loading"
                  wrapperStyle={{}}
                  wrapperClass="watch-load"
                />
                <p className="loading-text">
                  "The two most powerful warriors are patience and time." – Leo
                  Tolstoy
                </p>
              </div>
            </div>
          ) : (
            <>
              <Container className="parentWrapper">
                <div className="innerWrapper">
                  <div></div>
                  <div className="general-form-wrapper-gd">
                    <div className="header-section">
                      <h4 style={{ textAlign: 'center' }}>
                        {groupDetails.groupname && groupDetails.groupname}
                      </h4>
                    </div>
                    <Form className="FormContainer">
                      <div className="gd-body">
                        <p>
                          {groupDetails.description && groupDetails.description}
                        </p>
                      </div>
                      <div></div>
                      <div className="conditional-section">
                        {groupDetails.creatorId === userId ? (
                          <div className="creator-section">
                            <>
                              <p> You are the organizer</p>
                              <div>
                                <Button
                                  onClick={(e) => {
                                    navigateToGroup(e, groupId);
                                  }}
                                >
                                  View
                                </Button>
                              </div>
                            </>
                          </div>
                        ) : isMember ? (
                          <div className="membersection">
                            <Button
                              onClick={(e) =>
                                handleJoinLeaveGroup(
                                  e,
                                  userId,
                                  groupId,
                                  'LEAVE',
                                )
                              }
                            >
                              Leave
                            </Button>
                            <Button
                              onClick={(e) => {
                                navigateToGroup(e, groupId);
                              }}
                            >
                              View
                            </Button>
                          </div>
                        ) : (
                          <Button
                            onClick={(e) =>
                              handleJoinLeaveGroup(e, userId, groupId, 'JOIN')
                            }
                            type="button"
                            className="join-btn"
                            data-mdb-ripple-color="dark"
                          >
                            Join Group
                          </Button>
                        )}
                      </div>

                      <br></br>
                    </Form>
                  </div>
                </div>
              </Container>
              {/* Error Modal */}
              <Modal
                show={showErrorModal}
                onHide={() => setShowErrorModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowErrorModal(false)}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default GroupDetails;
