import React from 'react';
import { Button } from 'react-bootstrap';
import { BsLockFill } from 'react-icons/bs'; // Importing Bootstrap icons for the padlock

const PayButton = ({ disabled, children }) => {
  return (
    <Button
      variant="success"
      disabled={disabled}
      className="pay-button"
      style={{
        backgroundColor: '#4CAF50',
        fontSize: '18px',
        fontWeight: 'bold',
        borderRadius: '5px',
        padding: '10px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
      }}
    >
      <BsLockFill style={{ marginRight: '10px' }} />
      {children}
    </Button>
  );
};

export default PayButton;
