import React, { useState, useContext, useEffect } from 'react';
import { Container, Card, Button, ListGroup, Row, Col } from 'react-bootstrap';
import SubscribeSuccess from '../screens/SubscribeSuccess';
import { UserContext } from './UserContext';
import PaymentModal from './PaymentModal'; // Import the payment modal component
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const PlanSelectionPage = () => {
  const [selectedPlan, setSelectedPlan] = useState('');
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();
  // const [currentSubscription, setCurrentSubscription] = useState(null);

  const { userId, setUserId, userName, currentSubscription } =
    useContext(UserContext);

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    if (plan === 'price_1PH0uICPOAF7XDyrUypgphF0') {
      setShowModal(true); // Show modal when premium is selected
    } else {
      // Handle free plan selection (e.g., API call or context update)
      console.log('Free plan selected');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleManageSubscription = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${apiUrl}/stripe/create-portal`,
        { customerId: currentSubscription.plan.customerId },
        {
          headers: {
            'x-auth-token': token,
          },
        },
      );
      //   console.log(response);
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error creating customer portal session:', error);
    }
  };

  const handleShowSuccess = () => {
    setShowModal(false);
    setShowSuccessModal(true);
  };

  const handleContinue = () => {
    setShowSuccessModal(false);
    // Redirect to UserWelcome component
    navigate('/userwelcome');
  };

  return (
    <Container>
      <h2 className="text-center my-4">Choose Your Plan</h2>
      <div className="d-flex justify-content-center">
        <Row>
          <Col xs={12} md={6} className="plan-section">
            <Card style={{ width: '18rem', marginRight: '1rem' }}>
              <Card.Body>
                <Card.Title>Free Plan</Card.Title>
                <Card.Text>
                  A great start for individuals to manage personal goals.
                </Card.Text>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    Create 10 Cycles for each group
                  </ListGroup.Item>
                  <ListGroup.Item>Join up to 5 groups</ListGroup.Item>
                  <ListGroup.Item>Create up to 5 groups</ListGroup.Item>
                </ListGroup>
                {currentSubscription?.data?.plan == 'Free' ? (
                  <Button variant="secondary" disabled>
                    Currently on Free Plan
                  </Button>
                ) : (
                  <Button variant="primary" onClick={handleManageSubscription}>
                    Select Free Plan
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} className="plan-section second-plan">
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>Premium Plan</Card.Title>
                <Card.Text>
                  Enhanced features for users looking for more flexibility and
                  privacy.
                </Card.Text>
                <ListGroup variant="flush">
                  <ListGroup.Item>Create unlimited cycles</ListGroup.Item>
                  <ListGroup.Item>Create unlimited groups</ListGroup.Item>
                  <ListGroup.Item>Join unlimited groups</ListGroup.Item>
                </ListGroup>
                {currentSubscription?.data?.plan?.plan == 'Premium' ? (
                  <Button variant="secondary" disabled>
                    Currently on Premium Plan
                  </Button>
                ) : (
                  <Button
                    variant="success"
                    onClick={() =>
                      handleSelectPlan('price_1PH0uICPOAF7XDyrUypgphF0')
                    }
                  >
                    Select Premium Plan ($10/month)
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      {showModal && (
        <PaymentModal
          show={showModal}
          selectedPlan={selectedPlan}
          onHide={handleCloseModal}
          onSuccess={handleShowSuccess}
        />
      )}
      {showSuccessModal && (
        <SubscribeSuccess
          show={showSuccessModal}
          onHide={() => setShowSuccessModal(false)}
          onContinue={handleContinue}
        />
      )}
    </Container>
  );
};

export default PlanSelectionPage;
