// src/components/Layout.js
import React from 'react';
import './AlternatingSection.css';

const AlternatingSection = () => {
  const content = [
    {
      id: 1,
      heading: 'Discover Accountability Groups',
      image: 'img/hp-image1.png',
    },
    { id: 2, heading: 'Build your own groups', image: 'img/hp-image2.png' },
    { id: 3, heading: 'Set Goals together', image: 'img/hp-image3.png' },
    { id: 4, heading: 'Review & stay on course', image: 'img/hp-image4.png' },
  ];

  return (
    <div className="layout-container">
      {content.map((item, index) => (
        <div key={item.id} className="layout-row">
          {index % 2 === 0 ? (
            <>
              <div className="layout-column heading">
                <h1>{item.heading}</h1>
              </div>
              <div className="layout-column image hp">
                <img src={item.image} alt={`Image ${item.id}`} />
              </div>
            </>
          ) : (
            <>
              <div className="layout-column image hp">
                <img src={item.image} alt={`Image ${item.id}`} />
              </div>
              <div className="layout-column heading">
                <h1>{item.heading}</h1>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default AlternatingSection;
