// GroupContext.js
import React, { createContext, useState } from 'react';

// Create a new context
export const GroupContext = createContext();

// Create a provider component for your context
export const GroupProvider = ({ children }) => {
  const [currentGroupId, setCurrentGroupId] = useState(null);
  // Initialize groupId state
  const [groupName, setGroupName] = useState(null);
  // Function to set the groupId
  const updateGroupId = (newGroupId) => {
    setCurrentGroupId(newGroupId);
  };

  // Return the provider with context value
  return (
    <GroupContext.Provider value={{ currentGroupId, updateGroupId }}>
      {children}
    </GroupContext.Provider>
  );
};
