import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import SideNav from '../components/SideNav';
import { Watch } from 'react-loader-spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function AcceptInvitePage() {
  const { invite_token } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [groupId, setGroupId] = useState();

  const apiUrl = process.env.REACT_APP_API_URL;

  // useEffect(() => {
  //   setLoading(true);
  //   const authToken = localStorage.getItem('token');
  //   console.log('authToken', authToken);
  //   console.log('invite_token', invite_token);
  //   // Retrieve the auth token from localStorage
  //   console.log('authToken');
  //   console.log(authToken);
  //   const isLoggedIn = Boolean(authToken); // Check if the auth token exists to consider user logged in

  //   if (isLoggedIn) {
  //     setLoggedIn(true);
  //   } else {
  //     setLoading(false);
  //     return; // Early return if not logged in
  //   }

  //   const acceptInvitation = async () => {
  //     try {
  //       console.log(
  //         'Dependencies - Invite Token:',
  //         invite_token,
  //         'API URL:',
  //         apiUrl,
  //       );

  //       console.log('Sending accept invitation request');
  //       const response = await axios.post(
  //         `${apiUrl}/groups/accept-invitation/${invite_token}`,
  //         {},
  //         {
  //           headers: {
  //             'x-auth-token': authToken,
  //           },
  //         },
  //       );
  //       const { groupId } = response.data;
  //       setLoading(false); // Update loading state based on the request success
  //       setGroupId(groupId);
  //     } catch (err) {
  //       setError('Failed to accept invitation or invitation has expired.');
  //       setLoading(false);
  //     }
  //   };

  //   acceptInvitation();
  // }, [invite_token, apiUrl]);

  // if (loading) return <p>Loading...</p>;
  // if (!loggedIn) return <p>Please login or sign up to continue.</p>;
  // if (error) return <p>Error: {error}</p>;

  return (
    <>
      <Row>
        <Col md={2} className="sidebar">
          <SideNav />
        </Col>
        <Col>
          {' '}
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-75">
              <div className="loading-section">
                <Watch
                  visible={true}
                  height="40"
                  width="40"
                  radius="48"
                  color="#4fa94d"
                  ariaLabel="watch-loading"
                  wrapperStyle={{}}
                  wrapperClass="watch-load"
                />
                <p className="loading-text">
                  "The two most powerful warriors are patience and time." – Leo
                  Tolstoy
                </p>
              </div>
            </div>
          ) : (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
              <h1
                style={{
                  color: '#4fa94d',
                  fontWeight: 'bold',
                  fontSize: '2em',
                }}
              >
                🎉 Congratulations! You've successfully joined a new group 🎉
              </h1>
              <p
                style={{
                  fontSize: '1.2em',
                  marginTop: '20px',
                  animation: 'blink 1s infinite', // Apply blinking animation here
                }}
              >
                Click{' '}
                <a
                  href={`/mygroups/${groupId}`}
                  style={{
                    color: '#007bff',
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                  }}
                  onClick={(e) => {
                    e.target.style.animation = 'none'; // Stop blinking after it's clicked
                  }}
                >
                  here
                </a>{' '}
                to view the group 🚀
              </p>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}

export default AcceptInvitePage;
