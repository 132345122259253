import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import FooterLanding from './FooterLanding';

import BookSlick from './BookSlick';
import {
  Routes,
  Route,
  useNavigate,
  useParams,
  useLocation,
} from 'react-router-dom';
import AlternatingSection from './AlternatingSection';

const LandingPage = () => {
  const navigate = useNavigate();
  const navigateToLogin = () => {
    // 👇️ navigate to /contacts
    navigate('/login');
  };

  const navigateToSignup = () => {
    // 👇️ navigate to /contacts
    navigate('/signup');

    //
  };
  return (
    <>
      <Container className="section-one pb-5">
        <Container>
          <Row>
            <Col>
              <div className="first-section">
                <p> Stay accountable,</p>
                <p> achieve your goals</p>
              </div>

              {/* <p style={{ maxWidth: "600px", textAlign: "center" }}>
                  Easily create and discover accountability groups, and
                  together, reach your goals faster. Join us today and start
                  achieving your dreams with Asquad.
                </p> */}

              <Row>
                {/* <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button variant="dark">Contribute </Button>
                </Col> */}
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button className="regiser-btn" onClick={navigateToSignup}>
                    Register for free
                  </Button>
                </Col>
              </Row>
              <Row>
                <p
                  style={{
                    paddingTop: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  Already have an account?{' '}
                  <p className="subtext-login" onClick={navigateToLogin}>
                    {' '}
                    Login now
                  </p>
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Container>
          <Row className="pb-5">
            <Row>
              <div className="image-wrap">
                <img
                  src="/img/acc_oneimage.png"
                  alt="Home"
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
            </Row>
          </Row>
        </Container>
      </Container>
      <Container className="books-section">
        <BookSlick />
      </Container>

      <Container>
        <AlternatingSection />
      </Container>

      <Container className="sectionFooter2">
        <Container>
          <div className="last-section pt-5">
            <p> Stay accountable,</p>
            <p> achieve your goals</p>
            <p> stay connected with peers </p>
          </div>

          <Row>
            {/* <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button variant="dark">Contribute </Button>
                </Col> */}
            <Col style={{ display: 'flex', justifyContent: 'center' }}>
              <Button className="regiser-btn-white" onClick={navigateToSignup}>
                Register for free today
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="FooterWrapper">
        <Container className="pt-5">
          <Row>
            <FooterLanding />
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default LandingPage;
