import React, { Component, useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { UserContext } from '../components/UserContext';
import { useNavigate, Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Watch } from 'react-loader-spinner';

const apiUrl = process.env.REACT_APP_API_URL;

const DashboardTable = ({ cycleFlag }) => {
  const { userId, setUserId, userName } = useContext(UserContext);
  const [activeCycles, setActiveCycles] = useState([]);
  const [allCycles, setAllCycles] = useState([]);
  const [loading, setLoading] = useState(true);
  //const [isAllCycles, setIsAllCycles] = useState(false);
  const [cycleStatus, setCycleStatus] = useState([]);
  const navigate = useNavigate();

  const currentDate = new Date();

  const calculateTimeElapsed = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    if (currentDate >= end) {
      return 100;
    } else {
      const totalDays = (end - start) / (1000 * 60 * 60 * 24);
      const elapsedDays = (currentDate - start) / (1000 * 60 * 60 * 24);
      const finalvalue = (elapsedDays / totalDays) * 100;
      const finalpcnt = finalvalue.toFixed(2).toString() + '%';
      return finalpcnt;
    }
  };

  const isGoalCompleted = (goal) => {
    return goal.subtasks.every((subtask) => {
      if (subtask.frequency === 'daily') {
        return subtask.subtaskProgress === 100;
      }
      return subtask.isCompleted;
    });
  };

  const determineStatus = (endDate, cycleMembers) => {
    const allGoalsCompleted = cycleMembers.every((member) =>
      member.goals.every((goal) => isGoalCompleted(goal)),
    );

    if (currentDate > new Date(endDate) && !allGoalsCompleted) {
      return 'incomplete';
    }

    return currentDate <= new Date(endDate)
      ? 'in-progress'
      : allGoalsCompleted
      ? 'completed'
      : 'incomplete';
  };

  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  function formatDateWithOrdinal(date) {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const suffix = getDaySuffix(day);
    return `${day}${suffix} ${month}`;
  }

  const formatCyclePeriod = (cycle) => {
    const startDate = new Date(cycle.startDate);
    const endDate = new Date(cycle.endDate);

    const formattedStartDate = formatDateWithOrdinal(startDate);
    const formattedEndDate = formatDateWithOrdinal(endDate);

    return ` ${formattedStartDate} - ${formattedEndDate}`;
  };

  const transformData = (response) => {
    return response
      .filter((cycle) => cycle.group !== null) // Filter out cycles where group is null
      .map((cycle, index) => {
        const startDate = cycle.startDate;
        const endDate = cycle.endDate;
        const timeElapsed = calculateTimeElapsed(startDate, endDate);
        const status = determineStatus(endDate, cycle.cycleMembers);

        return {
          id: index + 1,
          group: cycle.group.groupname,
          // period: `${new Date(startDate).toLocaleDateString()} - ${new Date(
          //   endDate,
          // ).toLocaleDateString()}`,
          timeelapsed: timeElapsed,
          period: formatCyclePeriod(cycle), // rounded to 2 decimal places
          status: status,
          link_id: `/mygroups/${cycle.group._id}/groupcycleoverview/review/${cycle._id}`,
        };
      });
  };

  const columns = [
    {
      name: ' Group',
      selector: (row) => row.group,
      sortable: true,
    },
    {
      name: 'Period',
      selector: (row) => row.period,
      sortable: true,
    },
    {
      name: 'Time elapsed',
      selector: (row) => row.timeelapsed,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <span
          className={`status-${row.status.replace(' ', '-').toLowerCase()}`}
        >
          {row.status}
        </span>
      ),
    },
    {
      name: '',
      selector: (row) => (
        <Link className="review-link" to={row.link_id}>
          Review
        </Link>
      ),
      sortable: false, // Typically, action links are not sortable
    },
  ];

  useEffect(() => {
    setLoading(true);
    const isLoggedIn = Boolean(localStorage.getItem('token'));
    const token = localStorage.getItem('token');

    if (!isLoggedIn) {
      navigate('/login');
    }

    const fetchActiveData = async () => {
      try {
        if (isLoggedIn) {
          const response = await axios.get(`${apiUrl}/cycles/active`, {
            headers: {
              'x-auth-token': token,
            },
          });
          //console.log('response.data ', response.data);

          const cycles = transformData(response.data);
          //  console.log('cycles: ', cycles);

          //const checkStructure = response.data.map((e)=>e)
          setActiveCycles(cycles);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchActiveData();

    const fetchAllData = async () => {
      try {
        if (isLoggedIn) {
          const response = await axios.get(`${apiUrl}/cycles/getallcycles`, {
            headers: {
              'x-auth-token': token,
            },
          });
          // console.log('response.data ', response.data);

          const allcycles = transformData(response.data);
          // console.log('cycles: ', cycles);

          //const checkStructure = response.data.map((e)=>e)
          setAllCycles(allcycles);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
    //setLoading(false)
  }, [userId]);

  return (
    <>
      {loading ? (
        <>
          <div className="d-flex justify-content-center align-items-center vh-75">
            <div className="loading-section">
              <Watch
                visible={true}
                height="40"
                width="40"
                radius="48"
                color="#4fa94d"
                ariaLabel="watch-loading"
                wrapperStyle={{}}
                wrapperClass="watch-load"
              />
              <p className="loading-text">
                "The two most powerful warriors are patience and time." – Leo
                Tolstoy
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className="data-table-wrapper">
          <DataTable
            pagination
            columns={columns}
            data={cycleFlag ? allCycles : activeCycles}
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
          />
        </div>
      )}
    </>
  );
};

export default DashboardTable;
