import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import PayButton from './PayButton';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('your_stripe_public_key');

// Assuming you have already initialized stripePromise as shown previously
const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false); // State to handle loading

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading true on submit

    if (!stripe || !elements) {
      setLoading(false);
      return; // Stripe.js has not loaded yet
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      console.log(result.error.message);
      setLoading(false); // Set loading false if there's an error
    } else {
      const token = result.token.id;
      // Here, you might make a call to your server to process the payment
      // For example: processPayment(token);
      // Then after response:
      // setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <PayButton disabled={loading}>
        {loading ? 'Processing...' : 'Pay $10 to Subscribe'}
      </PayButton>
    </form>
  );
};

const InjectedCheckoutForm = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);

export default InjectedCheckoutForm;
