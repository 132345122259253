// FloatingSaveButton.js
import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaRegSave } from 'react-icons/fa';
import '../App.css'; // Assuming you keep the CSS in a separate file

const FloatingSaveButton = ({ onClick, type = 'button' }) => {
  const renderSaveTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Save Changes
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="left" overlay={renderSaveTooltip}>
      <Button className="floating-save-btn" onClick={onClick} type={type}>
        <FaRegSave />
      </Button>
    </OverlayTrigger>
  );
};

export default FloatingSaveButton;
