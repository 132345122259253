import React, { Component, useEffect, useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { AdminContext } from '../components/AdminContext';
import { GroupContext } from '../components/GroupContext';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import Label from 'react-bootstrap/FormLabel';
import { Alert, Toast, ToastContainer } from 'react-bootstrap';
import MainLayout from '../components/MainLayout';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Tooltip from 'react-bootstrap/Tooltip';
import { Watch } from 'react-loader-spinner';
import { FaRegSave } from 'react-icons/fa';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import FloatingGoBackButton from './FloatingGoBackButton';
import FloatingSaveButton from './FloatingSaveButton';

import SearchableDropdown from '../screens/SearchableDropdown';

import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import SideNav from './SideNav';
const apiUrl = process.env.REACT_APP_API_URL;

const UpdateCycle = () => {
  //console.log(useParams());
  const [isDesktop, setIsDesktop] = useState(false);
  const navigate = useNavigate();
  const { groupId, cycleId } = useParams();
  const [groupDetails, setGroupDetails] = useState();
  const [loading, setLoading] = useState(true);

  const [formState, setFormState] = useState(null);

  const [startDate, setStartDate] = useState(); // empty startDate set when we start initially
  const [endDate, setEndDate] = useState(); // empty endDate set when we start initially

  const [selectedUsers, setSelectedUsers] = useState([]);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [users, setUsers] = useState([]);
  const [userNames, setUsernames] = useState([]);

  const [dateError, setDateError] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    const isLoggedIn = Boolean(localStorage.getItem('token'));

    const fetchData = async () => {
      setLoading(true);
      if (token) {
        try {
          const response = await axios.get(
            `${apiUrl}/cycles/${groupId}/${cycleId}`,
            {
              headers: {
                'x-auth-token': token,
              },
            },
          );

          if (groupId && isLoggedIn) {
            const response_gd = await axios.get(`${apiUrl}/groups/${groupId}`, {
              headers: {
                'x-auth-token': token,
              },
            });

            const group = response_gd.data;

            setGroupDetails(group);
          }

          // Assuming the response data is structured as the JSON you provided earlier
          const { startDate, endDate, group, cycleMembers } = response.data;

          setFormState(response.data);
          // console.log('Fetched and transformed data:', response.data);
          setLoading(false);
        } catch (error) {
          console.error('Fetching error:', error.message);
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [apiUrl, groupId, cycleId]);

  //   }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        const message =
          'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  //const groupId = useContext(GroupContext)

  const handleShowToast = (message, toastType) => {
    setToastMessage(message);
    setToastType(toastType);
    setShowToast(true);
    // Toast shows for 3 seconds
  };

  const deleteGoal = (userId, goalIndex) => {
    const updatedMembers = formState.cycleMembers.map((member) =>
      member.user === userId
        ? {
            ...member,
            goals: member.goals.filter((_, index) => index !== goalIndex),
          }
        : member,
    );
    setFormState((prev) => ({ ...prev, cycleMembers: updatedMembers }));
    setHasUnsavedChanges(true);
  };

  const deleteSubtask = (userId, goalIndex, subtaskIndex) => {
    const updatedMembers = formState.cycleMembers.map((member) =>
      member.user === userId
        ? {
            ...member,
            goals: member.goals.map((goal, gIndex) =>
              gIndex === goalIndex
                ? {
                    ...goal,
                    subtasks: goal.subtasks.filter(
                      (_, sIndex) => sIndex !== subtaskIndex,
                    ),
                  }
                : goal,
            ),
          }
        : member,
    );
    setFormState((prev) => ({ ...prev, cycleMembers: updatedMembers }));
    setHasUnsavedChanges(true);
  };

  const handleSelectUser = (selectedUserName) => {
    const user = users.find((u) => u.name === selectedUserName);
    if (
      user &&
      !formState.cycleMembers.some((member) => member.user === user._id)
    ) {
      const newMember = {
        user: user._id,
        goals: [],
      };
      setFormState((prev) => ({
        ...prev,
        cycleMembers: [...prev.cycleMembers, newMember],
      }));
    }
    setHasUnsavedChanges(true);
  };

  const addGoal = (userId) => {
    const updatedMembers = formState.cycleMembers.map((member) =>
      member.user === userId
        ? {
            ...member,
            goals: [...member.goals, { goalName: '', subtasks: [] }],
          }
        : member,
    );
    setFormState((prev) => ({ ...prev, cycleMembers: updatedMembers }));
    setHasUnsavedChanges(true);
  };

  const addSubtask = (userId, goalIndex) => {
    const updatedMembers = formState.cycleMembers.map((member) =>
      member.user === userId
        ? {
            ...member,
            goals: member.goals.map((goal, index) =>
              index === goalIndex
                ? {
                    ...goal,
                    subtasks: [
                      ...goal.subtasks,
                      { subTaskName: '', isCompleted: false },
                    ],
                  }
                : goal,
            ),
          }
        : member,
    );
    setFormState((prev) => ({ ...prev, cycleMembers: updatedMembers }));
    setHasUnsavedChanges(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(cycleId);
    if (!dateError) {
      const token = localStorage.getItem('token');
      try {
        await axios.put(`${apiUrl}/cycles/${cycleId}`, formState, {
          headers: { 'x-auth-token': token },
        });
        handleShowToast('Cycle Updated Successfully!', 'success');
      } catch (error) {
        console.error(error);
        handleShowToast('Unexpected Error!', 'danger');
      }
    }
    setHasUnsavedChanges(false);
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    if (formState.endDate && newStartDate > formState.endDate) {
      setDateError('Start date cannot be later than end date.');
    } else {
      setDateError('');
    }
    setFormState((prev) => ({ ...prev, startDate: newStartDate }));
    setHasUnsavedChanges(true);
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    if (formState.startDate && formState.startDate > newEndDate) {
      setDateError('End date cannot be earlier than start date.');
    } else {
      setDateError('');
    }
    setFormState((prev) => ({ ...prev, endDate: newEndDate }));
    setHasUnsavedChanges(true);
  };

  // Function to navigate back
  const navigateBack = () => {
    if (
      !hasUnsavedChanges ||
      window.confirm(
        'You have unsaved changes. Are you sure you want to leave?',
      )
    ) {
      navigate(-1);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Save Changes
    </Tooltip>
  );

  return (
    <>
      <FloatingGoBackButton onClick={navigateBack} />
      <Row>
        <Col md={2} className="sidebar">
          <SideNav />
        </Col>
        <Col md={10}>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-75">
              <div className="loading-section">
                <Watch
                  visible={true}
                  height="40"
                  width="40"
                  radius="48"
                  color="#4fa94d"
                  ariaLabel="watch-loading"
                  wrapperStyle={{}}
                  wrapperClass="watch-load"
                />
                <p className="loading-text">
                  "The two most powerful warriors are patience and time." – Leo
                  Tolstoy
                </p>
              </div>
            </div>
          ) : (
            <div>
              <Helmet>
                <title>Update Cycle </title>
                <meta
                  name="description"
                  content="Asquad - accountability made easy"
                />
              </Helmet>
              <Container>
                <MainLayout title="Update Cycle">
                  <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={3000}
                    autohide
                    bg={toastType === 'success' ? 'success' : 'danger'}
                    style={{
                      position: 'fixed',
                      bottom: 70,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      zIndex: 9999,
                      color: 'white ',
                    }}
                  >
                    <Toast.Body>{toastMessage}</Toast.Body>
                  </Toast>
                  <div className="header-update">
                    <p className="h-gd-title">
                      {groupDetails && groupDetails.groupname}{' '}
                    </p>
                  </div>

                  <Form className="form-wrapper" onSubmit={handleSubmit}>
                    {/* Start and End Date Inputs */}

                    <Row className="justify-content-center mb-3">
                      <Col md={6} lg={3}>
                        <Form.Group>
                          <Form.Label>Start Date</Form.Label>
                          <Form.Control
                            type="date"
                            name="startDate"
                            value={
                              formState
                                ? formState.startDate.slice(0, 10)
                                : 'Loading'
                            }
                            required="true"
                            onChange={handleStartDateChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={3}>
                        <Form.Group>
                          <Form.Label>End Date</Form.Label>
                          <Form.Control
                            type="date"
                            name="endDate"
                            value={
                              formState
                                ? formState.endDate.slice(0, 10)
                                : 'Loading'
                            }
                            onChange={handleEndDateChange}
                            required="true"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    {formState.cycleMembers.map((member, mIndex) => (
                      <div key={mIndex} className="mb-3">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Card.Title>{member.user.name}</Card.Title>
                          <Button
                            className="add-goal-btn"
                            variant="primary"
                            onClick={() => addGoal(member.user)}
                          >
                            Add Goal
                          </Button>
                        </div>
                        {member.goals.map((goal, gIndex) => (
                          <div key={gIndex} className="mt-2">
                            <Card.Subtitle>
                              {/* Dynamic Title Construction */}
                              {`${member.user.name}'s Goal #${gIndex + 1}`}
                            </Card.Subtitle>
                            <InputGroup className="mb-2">
                              <Form.Control
                                type="text"
                                value={goal.goalName}
                                onChange={(e) => {
                                  const goalName = e.target.value;
                                  setFormState((prev) => {
                                    const updatedCycleMembers =
                                      prev.cycleMembers.map((mem) =>
                                        mem.user === member.user
                                          ? {
                                              ...mem,
                                              goals: mem.goals.map((g, gi) =>
                                                gi === gIndex
                                                  ? { ...g, goalName: goalName }
                                                  : g,
                                              ),
                                            }
                                          : mem,
                                      );
                                    return {
                                      ...prev,
                                      cycleMembers: updatedCycleMembers,
                                    };
                                  });
                                }}
                                placeholder="Goal Name"
                                className="goal-input"
                                required={true}
                              />
                              <Button
                                className="delete-goal-btn"
                                variant="danger"
                                onClick={() => deleteGoal(member.user, gIndex)}
                              >
                                X
                              </Button>
                            </InputGroup>
                            <Button
                              className="add-sbtask-btn"
                              variant="secondary"
                              onClick={() => addSubtask(member.user, gIndex)}
                            >
                              Add Subtask
                            </Button>
                            {goal.subtasks.map((subtask, sIndex) => (
                              <InputGroup className="mb-2" key={sIndex}>
                                <Form.Control
                                  type="text"
                                  value={subtask.subTaskName}
                                  onChange={(e) => {
                                    const subTaskName = e.target.value;
                                    setFormState((prev) => {
                                      const updatedCycleMembers =
                                        prev.cycleMembers.map((mem) =>
                                          mem.user === member.user
                                            ? {
                                                ...mem,
                                                goals: mem.goals.map((g, gi) =>
                                                  gi === gIndex
                                                    ? {
                                                        ...g,
                                                        subtasks:
                                                          g.subtasks.map(
                                                            (s, si) =>
                                                              si === sIndex
                                                                ? {
                                                                    ...s,
                                                                    subTaskName:
                                                                      subTaskName,
                                                                  }
                                                                : s,
                                                          ),
                                                      }
                                                    : g,
                                                ),
                                              }
                                            : mem,
                                        );
                                      return {
                                        ...prev,
                                        cycleMembers: updatedCycleMembers,
                                      };
                                    });
                                  }}
                                  placeholder="Subtask Name"
                                  required="true"
                                  className="subtask-input"
                                />

                                <Form.Check
                                  type="checkbox"
                                  checked={subtask.frequency === 'daily'} // Update checked based on subtask.frequency
                                  onChange={(e) => {
                                    const isDaily = e.target.checked;
                                    console.log(e.target.checked);

                                    setFormState((prev) => {
                                      const updatedCycleMembers =
                                        prev.cycleMembers.map((mem) =>
                                          mem.user === member.user
                                            ? {
                                                ...mem,
                                                goals: mem.goals.map((g, gi) =>
                                                  gi === gIndex
                                                    ? {
                                                        ...g,
                                                        subtasks:
                                                          g.subtasks.map(
                                                            (s, si) =>
                                                              si === sIndex
                                                                ? {
                                                                    ...s,
                                                                    frequency:
                                                                      isDaily
                                                                        ? 'daily'
                                                                        : 'one-off', // Update frequency based on checkbox state
                                                                  }
                                                                : s,
                                                          ),
                                                      }
                                                    : g,
                                                ),
                                              }
                                            : mem,
                                        );
                                      return {
                                        ...prev,
                                        cycleMembers: updatedCycleMembers,
                                      };
                                    });
                                  }}
                                  label="Daily Task"
                                />

                                <Button
                                  className="delete-sbtask-btn"
                                  variant="outline-danger"
                                  onClick={() =>
                                    deleteSubtask(member.user, gIndex, sIndex)
                                  }
                                >
                                  X
                                </Button>
                              </InputGroup>
                            ))}
                          </div>
                        ))}
                      </div>
                    ))}

                    {dateError && <Alert variant="danger">{dateError}</Alert>}

                    <FloatingSaveButton type="submit" />

                    {/* <Button className="create-cycle-btn" type="submit" >Submit Form</Button> */}
                  </Form>
                </MainLayout>
              </Container>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default UpdateCycle;
