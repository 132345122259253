import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import SideNav from './SideNav';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Watch } from 'react-loader-spinner';
import { Helmet } from 'react-helmet-async';
import { LuCalendarClock } from 'react-icons/lu';
import MainLayout from './MainLayout';
import {
  Container,
  Row,
  Col,
  Card,
  ProgressBar,
  Spinner,
  Toast,
  Modal,
  Button,
} from 'react-bootstrap';
import './GroupCycleReview.css';

const apiUrl = process.env.REACT_APP_API_URL;

const GroupReviewOverview = () => {
  const navigate = useNavigate();
  const { cycleId, groupId } = useParams(); // Assuming cycleId is passed as a URL parameter
  const [cycle, setCycle] = useState(null);
  const [selectedSubtask, setSelectedSubtask] = useState(null);
  const [checkedSubtask, setCheckedSubtask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);

  const [subtaskChanges, setSubtaskChanges] = useState({});

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success'); // 'success' or 'danger'

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [activeKey, setActiveKey] = useState(null); // State to handle the active accordion item
  const [confettiOpacity, setConfettiOpacity] = useState(1);
  const [groupDetails, setGroupDetails] = useState();
  const [totalProgress, setTotalProgress] = useState();
  const [selectedDates, setSelectedDates] = useState({});
  const [subtaskData, setSubtaskData] = useState({});
  const [subtaskProgress, setSubtaskProgress] = useState({});
  const [totalGoalProgress, setTotalGoalProgress] = useState({});
  const [showModal, setShowModal] = useState(false);

  const [expandedSubtasks, setExpandedSubtasks] = useState([]);
  const toggleSubtask = (subtaskId) => {
    setExpandedSubtasks(
      (prev) =>
        prev.includes(subtaskId)
          ? prev.filter((id) => id !== subtaskId) // Collapse
          : [...prev, subtaskId], // Expand
    );
  };

  // Function to create subtask progress object from an array of objects
  const createSubtaskProgressObjectFromArray = (dataArray) => {
    const subtaskProgressObject = {};

    // Iterate through each object in the array
    dataArray.forEach((data) => {
      // Iterate through goals
      data.goals.forEach((goal) => {
        // Iterate through subtasks
        goal.subtasks.forEach((subtask) => {
          // Check if subtask has subtaskProgress defined
          if (subtask.subtaskProgress !== undefined) {
            // Add subtask ID and progress to object
            subtaskProgressObject[subtask._id] =
              subtask.subtaskProgress.toFixed(2);
          }
        });
      });
    });

    return subtaskProgressObject;
  };

  const CustomProgressBar = ({ progress }) => {
    const circlePosition = `${progress}%`; // Calculate position for the circle

    return (
      <div className="progress-container">
        <div className="progress-bar" />
        <div
          className="progress-fill"
          style={{ width: circlePosition }} // Width based on progress
        />
        <div
          className="progress-circle"
          style={{ left: circlePosition }} // Position based on progress
        />
      </div>
    );
  };
  useEffect(() => {
    setHasUnsavedChanges(Object.keys(subtaskChanges).length > 0);
  }, [subtaskChanges]);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem('token'); //
    const isLoggedIn = Boolean(localStorage.getItem('token'));
    const fetchCycle = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/cycles/${groupId}/${cycleId}`,
          {
            headers: {
              'x-auth-token': token,
            },
          },
        );
        console.log('response.data', response.data);
        setCycle(response.data);

        const subtaskProgressObject = createSubtaskProgressObjectFromArray(
          response.data.cycleMembers,
        );

        setSubtaskProgress(subtaskProgressObject);
        //  setTotalGoalProgress(goalProgressObject);

        // Check if adminId is defined
        const response_gd = await axios.get(`${apiUrl}/groups/${groupId}`, {
          headers: {
            'x-auth-token': token,
          },
        });

        const group = response_gd.data;

        setGroupDetails(group);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch data');
        setLoading(false);
        console.error(err);
      }
    };

    fetchCycle();
    if (cycle) {
      //console.log('cycle: ', cycle);
      //const subtaskProgresss = cycle.map((cm) => cm.goals);
    }
    //calculateProgress();
  }, [cycleId, groupId]);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        const message =
          'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  // Function to calculate total progress for each user
  const calculateTotalProgress = (goals) => {
    const totalSubtasks = goals.flatMap((goal) => goal.subtasks).length;
    const completedSubtasks = goals
      .flatMap((goal) => goal.subtasks)
      .filter((st) => st.isCompleted).length;
    return ((completedSubtasks / totalSubtasks) * 100).toFixed(2);
  };

  function formatDateWithOrdinal(date) {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const suffix = getDaySuffix(day);
    return `${day}${suffix} ${month}`;
  }

  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  const [selectedMember, setSelectedMember] = useState(null);
  const [tasks, setTasks] = useState({});

  const handleShowModal = (cyclemember) => {
    setSelectedMember(cyclemember);
    const initialTasks = cyclemember.goals.reduce((acc, goal) => {
      goal.subtasks.forEach((subtask) => {
        acc[subtask._id] = subtask.isCompleted;
      });
      return acc;
    }, {});
    setTasks(initialTasks);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMember(null);
  };

  useEffect(() => {
    // Update selectedMember whenever cycle changes
    const updatedMember = cycle?.cycleMembers.find(
      (member) => member._id === selectedMember?._id, // Use optional chaining
    );
    if (updatedMember) {
      setSelectedMember(updatedMember);
    }
  }, [cycle]); // Dependency on `cycle`

  const handleTaskToggle = (subtaskId) => {
    setTasks((prevTasks) => ({
      ...prevTasks,
      [subtaskId]: !prevTasks[subtaskId],
    }));
  };

  const formatCyclePeriod = () => {
    const startDate = new Date(cycle.startDate);
    const endDate = new Date(cycle.endDate);

    const formattedStartDate = formatDateWithOrdinal(startDate);
    const formattedEndDate = formatDateWithOrdinal(endDate);

    return ` ${formattedStartDate} - ${formattedEndDate}`;
  };
  const calculateTotalOverallProgressOfPerson = (goals) => {
    let totalSubtasks = 0;
    let completedSubtasks = 0;

    // Iterate over each goal and subtask to count completed subtasks
    goals.forEach((goal) => {
      goal.subtasks.forEach((subtask) => {
        totalSubtasks++;
        if (subtask.isCompleted) {
          completedSubtasks++;
        }
      });
    });

    // Calculate overall progress percentage
    return totalSubtasks > 0
      ? Math.round((completedSubtasks / totalSubtasks) * 100)
      : 0;
  };
  useEffect(() => {
    const fetchCycle = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `${apiUrl}/cycles/${groupId}/${cycleId}`,
          {
            headers: { 'x-auth-token': token },
          },
        );
        setCycle(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch data');
        setLoading(false);
        console.error(err);
      }
    };

    fetchCycle();
  }, [cycleId, groupId]);
  useEffect(() => {
    if (cycle) {
      const initialTasks = {};
      cycle.cycleMembers.forEach((member) =>
        member.goals.forEach((goal) =>
          goal.subtasks.forEach((subtask) => {
            initialTasks[subtask._id] = subtask.isCompleted;
          }),
        ),
      );
      setTasks(initialTasks);
    }
  }, [cycle]);

  const handleSubtaskChange = (
    cyclememberId,
    goalId,
    subtaskId,
    isCompleted,
  ) => {
    const key = `${cyclememberId}-${goalId}-${subtaskId}`;
    setSubtaskChanges((prev) => ({
      ...prev,
      [key]: !isCompleted, // Toggle the current state
    }));

    // Trigger confetti when a subtask is completed
    if (!isCompleted) {
      setShowConfetti(true);
      setConfettiOpacity(1); // Reset opacity to full when showing confetti
      setTimeout(() => {
        setConfettiOpacity(0); // Start fading out after 5 seconds
        setTimeout(() => setShowConfetti(false), 500); // Hide confetti completely after fade
      }, 2000);
    }

    // Optimistically update the cycle state
    setCycle((prevCycle) => {
      return {
        ...prevCycle,
        cycleMembers: prevCycle.cycleMembers.map((cyclemember) => {
          if (cyclemember._id === cyclememberId) {
            return {
              ...cyclemember,
              goals: cyclemember.goals.map((goal) => {
                if (goal._id === goalId) {
                  return {
                    ...goal,
                    subtasks: goal.subtasks.map((subtask) => {
                      if (subtask._id === subtaskId) {
                        return { ...subtask, isCompleted: !isCompleted };
                      }
                      return subtask;
                    }),
                  };
                }
                return goal;
              }),
            };
          }
          return cyclemember;
        }),
      };
    });

    //setSelectedSubtask({ cyclememberId, goalId, subtaskId });
    setCheckedSubtask({ cyclememberId, goalId, subtaskId });
    // handleSaveChanges();
  };

  const displayToast = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setShowToast(true);
  };

  // const generateAvatarUrl = (name) => {
  //   const initials = name
  //     .split(' ')
  //     .map((word) => word.charAt(0).toUpperCase())
  //     .join('');
  //   return `https://ui-avatars.com/api/?name=${initials}&background=606c80&color=ffffff&size=40`; // Adjust size as needed
  // };

  const generateAvatar = (username) => {
    // Encode the username to handle special characters
    const encodedUsername = encodeURIComponent(username);

    // Use the pixel-art style, but you can change this to any other style
    const style = 'pixel-art';

    // Construct the URL
    const avatarUrl = `https://api.dicebear.com/9.x/${style}/svg?seed=${encodedUsername}`;

    return avatarUrl;
  };
  // Example usage

  const getLastUpdatedText = (lastUpdated) => {
    console.log('print lastUpdated:', lastUpdated);
    if (!lastUpdated) return ''; // Handle missing lastUpdated case

    const parsedDate = new Date(lastUpdated);
    if (isNaN(parsedDate)) {
      console.error('Invalid date format for lastUpdated:', lastUpdated);
      return '';
    }

    const now = new Date();
    const diffInMs = now - parsedDate;
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    if (diffInDays === 0) return 'Last updated today';
    if (diffInDays === 1) return 'Last updated 1 day ago';
    return `Last updated ${diffInDays} days ago`;
  };

  const handleSaveChanges = async () => {
    const updates = Object.entries(subtaskChanges).map(([key, isCompleted]) => {
      const [cyclememberId, goalId, subtaskId] = key.split('-');
      // console.log("")
      return axios
        .patch(`${apiUrl}/cycles/${cycleId}/subtasks/toggle`, {
          cyclememberId,
          goalId,
          subtaskId,
        })
        .catch((error) => {
          console.error('Error updating subtask:', error);
          displayToast('Error updating subtasks!', 'danger');
        });
    });

    Promise.all(updates)
      .then(() => {
        displayToast('All changes saved successfully!', 'success');
        setSubtaskChanges({});
      })
      .catch((error) => {
        console.error('Error saving all changes:', error);
        displayToast('Failed to save some changes!', 'danger');
      });
  };
  return (
    <>
      <Row className="main-container">
        <Col md={2} className="sidebar">
          <SideNav />
        </Col>
        <Col>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-75">
              <div className="loading-section">
                <Watch
                  visible={true}
                  height="40"
                  width="40"
                  radius="48"
                  color="#4fa94d"
                  ariaLabel="watch-loading"
                  wrapperStyle={{}}
                  wrapperClass="watch-load"
                />
                <p className="loading-text">
                  "The two most powerful warriors are patience and time." – Leo
                  Tolstoy
                </p>
              </div>
            </div>
          ) : (
            <div>
              <Helmet>
                <title>Review Cycle</title>
                <meta
                  name="description"
                  content="Asquad - accountability made easy"
                />
              </Helmet>

              <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={3000}
                autohide
                bg={toastType === 'success' ? 'success' : 'danger'}
                style={{
                  position: 'fixed',
                  bottom: 70,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 9999,
                  color: 'white ',
                }}
              >
                <Toast.Body>{toastMessage}</Toast.Body>
              </Toast>

              <Row className="review-row">
                <div className="review-header">
                  <p>{groupDetails && groupDetails.groupname}</p>
                </div>
                <div className="period-section-header">
                  <div className="icon-calendar">
                    <LuCalendarClock />
                  </div>

                  <p className="period-title">{formatCyclePeriod(cycle)}</p>
                </div>

                {cycle?.cycleMembers.map((cyclemember) => {
                  console.log('cyclemember ', cyclemember);

                  const getMostRecentLastUpdated = (goals) => {
                    let latestDate = null;

                    goals.forEach((goal) => {
                      // Check the lastUpdated at the goal level
                      if (
                        !latestDate ||
                        new Date(goal.lastUpdated) > new Date(latestDate)
                      ) {
                        latestDate = goal.lastUpdated;
                      }

                      // Check each subtask's lastUpdated date
                      goal.subtasks.forEach((subtask) => {
                        if (
                          !latestDate ||
                          new Date(subtask.lastUpdated) > new Date(latestDate)
                        ) {
                          latestDate = subtask.lastUpdated;
                        }
                      });
                    });

                    return latestDate;
                  };

                  const mostRecentLastUpdated = getMostRecentLastUpdated(
                    cyclemember.goals,
                  );
                  const lastUpdatedText = getLastUpdatedText(
                    mostRecentLastUpdated,
                  );
                  return (
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      key={cyclemember._id}
                      className="d-flex  justify-content-center"
                    >
                      <Card className="text-center shadow-sm review-card mx-auto mx-lg-0">
                        <Card.Header className="d-flex align-items-center review-card-header">
                          {/* Example avatar */}
                          <div className="avatar-gradient me-3">
                            <img
                              src={generateAvatar(cyclemember.user.name)} //} // Set the generated avatar URL here
                              alt="Avatar"
                              className="rounded-circle"
                              width="40"
                              height="40"
                            />
                          </div>
                          {/* <img
          src={generateAvatarUrl(cyclemember.user.name)} // Replace with the avatar URL
          alt="Avatar"
          className="rounded-circle me-3"
          width="40"
          height="40"
        /> */}
                          <span className="review-username">
                            {cyclemember.user.name}
                          </span>
                        </Card.Header>

                        <Card.Body className="review-card-body">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <span className="progress-text">Progress</span>
                            <span className="percentage-text">
                              {calculateTotalOverallProgressOfPerson(
                                cyclemember.goals,
                              )}
                              %
                            </span>
                          </div>
                          {/* 
        <ProgressBar
          now={calculateTotalOverallProgressOfPerson(
            cyclemember.goals,
          )}
          label={`${calculateTotalOverallProgressOfPerson(
            cyclemember.goals,
          )}%`}
          variant="success"
          className="mb-2"
        /> */}
                          <CustomProgressBar
                            progress={calculateTotalOverallProgressOfPerson(
                              cyclemember.goals,
                            )}
                          />

                          <div className="d-flex justify-content-between align-items-center text-muted review-footer">
                            <span className="last-updated-text">
                              {lastUpdatedText}
                            </span>
                            <span
                              className="review-button"
                              onClick={() => handleShowModal(cyclemember)}
                              style={{ cursor: 'pointer' }}
                            >
                              Review
                            </span>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
              {/* Modal Popup */}

              <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                  <Modal.Title>Task Checklist</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {selectedMember ? (
                    selectedMember.goals.map((goal) => (
                      <div key={goal._id} className="mb-3">
                        <h5>{goal.goalName}</h5>
                        {goal.subtasks.map((subtask) => (
                          <Form.Check
                            key={subtask._id}
                            type="checkbox"
                            label={subtask.subTaskName}
                            checked={subtask.isCompleted}
                            onChange={() =>
                              handleSubtaskChange(
                                selectedMember._id,
                                goal._id,
                                subtask._id,
                                subtask.isCompleted,
                                subtask.subTaskName,
                              )
                            }
                            className="ms-3"
                          />
                        ))}
                      </div>
                    ))
                  ) : (
                    <p>No tasks available</p>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <div className="save-btn-goals" onClick={handleSaveChanges}>
                    {' '}
                    save
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default GroupReviewOverview;
