import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import React, { Component, useEffect, useState, useContext } from 'react';
import { UserContext } from '../components/UserContext';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Watch } from 'react-loader-spinner';
import './BrowseGroups.css';
import { PiBrowsersDuotone } from 'react-icons/pi';
import { IoMdPeople } from 'react-icons/io';
import { GoPeople } from 'react-icons/go';
import { BsPeople } from 'react-icons/bs';
import { GrPowerCycle } from 'react-icons/gr';
import { RxArrowRight } from 'react-icons/rx';
import BoxGroup from './BoxGroup';
import SideNav from '../components/SideNav';
import {
  IoIosPeople,
  IoIosCreate,
  IoIosEye,
  IoIosHome,
  IoMdPerson,
} from 'react-icons/io';
const apiUrl = process.env.REACT_APP_API_URL;
const BrowseGroups = () => {
  const navigate = useNavigate();

  const { userId } = useContext(UserContext);
  const [allGroups, setAllGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem('token'); //
    const isLoggedIn = Boolean(localStorage.getItem('token'));
    const fetchData = async () => {
      try {
        if (userId && isLoggedIn) {
          const response = await axios.get(`${apiUrl}/groups`);
          const publicGroups = response.data.filter(
            (group) => !group.isPrivate,
          ); // Filter for public groups
          ///  console.log(response.data);
          setAllGroups(publicGroups);
        } else {
          navigate('/signup');
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    //  console.log("inOverview");
  }, [userId]);

  return (
    <>
      <Row className="browse-groups">
        {/* Sidebar navigation */}
        <Col md={2} className="sidebar">
          <SideNav />
        </Col>
        <Col md={10}>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-75">
              <div className="loading-section">
                <Watch
                  visible={true}
                  height="40"
                  width="40"
                  radius="48"
                  color="#4fa94d"
                  ariaLabel="watch-loading"
                  wrapperStyle={{}}
                  wrapperClass="watch-load"
                />
                <p className="loading-text">
                  "The two most powerful warriors are patience and time." – Leo
                  Tolstoy
                </p>
              </div>
            </div>
          ) : (
            <div className="content-section">
              <Row>
                <h3 className="page-title-browse-groups mobile-view">
                  Browse Groups
                </h3>
              </Row>
              <Row>
                {allGroups.map((group) => {
                  return (
                    <Col
                      key={group._id}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      className="mb-4 box-section"
                    >
                      <BoxGroup
                        groupname={group.groupname}
                        description={group.description}
                        numberOfCycles={group.TotalNoOfCycles}
                        groupId={group._id}
                        numberOfMembers={group.TotalNoOfUsers}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          )}
        </Col>
        {/*  */}
      </Row>
    </>
  );
};

export default BrowseGroups;
