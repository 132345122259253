import React, { Component, useEffect, useState, useContext } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { UserContext } from '../components/UserContext';
import { useNavigate, Navigate } from 'react-router-dom';
import { Watch } from 'react-loader-spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SideNav from '../components/SideNav';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import MainLayout from '../components/MainLayout';
const apiUrl = process.env.REACT_APP_API_URL;
function Cycle(props) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const isLastCard = props.cycle === props.cycles[props.cycles.length - 1];

  return (
    <Card
      className={`card h-200 custom-style ${isLastCard ? 'last-card' : ''}`}
    >
      <Card.Body>
        <Row>
          <Col className="col-4 d-flex align-items-center">
            <div className="groupName mx-auto">{props.groupName}</div>
          </Col>
          <Col className="col-4 d-flex align-items-center">
            {' '}
            <div className="cyclePeriod   d-flex align-items-center">
              <div className="cycleItem  d-flex align-items-center">
                <h6 className="p-2  d-flex align-items-center">
                  {new Date(props.cycle.startDate).toLocaleDateString(
                    'us-EN',
                    options,
                  )}{' '}
                  -{' '}
                  {new Date(props.cycle.endDate).toLocaleDateString(
                    'us-EN',
                    options,
                  )}
                </h6>{' '}
              </div>
            </div>
          </Col>
          <Col className="col-2 d-flex align-items-center">
            <div className="custom-Button mx-auto">
              {' '}
              <Link
                to={`/mygroups/${props.groupId}/groupcycleoverview/review/${props.cycle._id}`}
              >
                <img
                  src={process.env.PUBLIC_URL + '/img/review-v1.png'}
                  alt="review"
                />
                review
              </Link>{' '}
            </div>
          </Col>
          <Col className="col-2  d-flex align-items-center">
            <div className="custom-Button mx-auto">
              <Link to={`/update/${props.groupId}/${props.cycle._id}`}>
                <img
                  src={process.env.PUBLIC_URL + '/img/edit-v1.png'}
                  alt="update"
                />
                update
              </Link>{' '}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

const WelcomeUser = () => {
  const { userId, setUserId, userName } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [activeCycles, setActiveCycles] = useState([]);
  const navigate = useNavigate();

  const navigateToMyGroups = () => {
    // 👇️ navigate to /contacts
    navigate('/mygroups');
    //setExpanded(false); //
  };
  const navigateToCreateGroups = () => {
    navigate('/creategroup');
  };

  const navigateToBrowseGroups = () => {
    navigate('/browsegroups');
  };

  useEffect(() => {
    setLoading(true);
    const isLoggedIn = Boolean(localStorage.getItem('token'));
    const token = localStorage.getItem('token');

    if (!isLoggedIn) {
      navigate('/login');
    }

    const fetchData = async () => {
      try {
        if (isLoggedIn) {
          const response = await axios.get(`${apiUrl}/cycles/active`, {
            headers: {
              'x-auth-token': token,
            },
          });
          setActiveCycles(response.data);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    //setLoading(false)
  }, [userId]);

  return (
    <>
      <Row>
        <Col md={2} className="sidebar">
          <SideNav />
        </Col>

        <Col md={10}>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-75">
              <div className="loading-section">
                <Watch
                  visible={true}
                  height="40"
                  width="40"
                  radius="48"
                  color="#4fa94d"
                  ariaLabel="watch-loading"
                  wrapperStyle={{}}
                  wrapperClass="watch-load"
                />
                <p className="loading-text">
                  "The two most powerful warriors are patience and time." – Leo
                  Tolstoy
                </p>
              </div>
            </div>
          ) : (
            <>
              <Helmet>
                <title>Active cycles</title>
                <meta
                  name="description"
                  content="Asquad - accountability made easy"
                />
              </Helmet>
              <Container className="active-cycle-wrapper">
                <MainLayout title="Active Cycles">
                  <p className="h-gd-title"> Below are your current goals </p>
                  {activeCycles.length === 0 ? ( // Check if cycles.length is zero
                    <Row className="d-flex justify-content-center align-items-center ">
                      <div className="d-flex justify-content-center wrapper-empty">
                        <div className="text-empty">
                          {' '}
                          No Active cycles found :)
                        </div>
                      </div>
                    </Row>
                  ) : (
                    <>
                      {activeCycles.map((currentCycle) => (
                        <Cycle
                          cycle={currentCycle}
                          startDate={currentCycle.startDate}
                          endDate={currentCycle.endDate}
                          key={currentCycle._id}
                          cycles={activeCycles}
                          groupId={currentCycle.group._id}
                          cycleId={currentCycle}
                          groupName={currentCycle.group.groupname}
                        />
                      ))}
                    </>
                  )}

                  {/* <Container className="parentWrapper">
        <div className="innerWrapper">
          <div></div>
          <div className="general-form-wrapper pt-5">
            <Form className="FormContainer">
              <h4 style={{ textAlign: "center" }}>Hi, {userName}</h4>
              <div className="buttonWrapper">
                <Button
                  type="button"
                  className="btn btn-outline-dark customWidthBtn"
                  data-mdb-ripple-color="dark"
                  onClick={navigateToMyGroups}
                >
                  My Groups
                </Button>
              </div>
              <div className="buttonWrapper">
                <Button
                  type="button"
                  className="btn btn-outline-dark customWidthBtn"
                  data-mdb-ripple-color="dark"
                  onClick={navigateToCreateGroups}
                >
                  Create Groups
                </Button>
              </div>
              <div className="buttonWrapper">
                <Button
                  type="button"
                  className="btn btn-outline-dark customWidthBtn"
                  data-mdb-ripple-color="dark"
                  onClick={navigateToBrowseGroups}
                >
                  Browse Groups
                </Button>
              </div>
              <br></br>
            </Form>
          </div>
        </div>
*/}
                </MainLayout>
              </Container>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default WelcomeUser;
