import React, { useState, useRef, useEffect } from 'react';
// Ensure you import the CSS as suggested below

function SearchableDropdown({ options, onSelect }) {
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Filtering the options based on the search input
  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(search.toLowerCase()),
  );

  // Event handler to detect clicks outside of the component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <input
          type="text"
          className="dropdown-input"
          placeholder="Select a name..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      {isOpen && (
        <ul className="dropdown-list">
          {filteredOptions &&
            filteredOptions.map((option, index) => (
              <li
                key={index}
                className="dropdown-item"
                onClick={() => {
                  setSearch(option);
                  onSelect(option); // Notify parent
                  setIsOpen(false);
                }}
                onMouseDown={(e) => e.preventDefault()} // This prevents the blur event from firing on click
              >
                {option}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}

export default SearchableDropdown;

// import React, { useState } from 'react';
// //import './SearchableDropdown.css'; // Make sure this file exists and is styled as before

// function SearchableDropdown({ options,onSelect }) {
//   const [search, setSearch] = useState('');
//   const [isOpen, setIsOpen] = useState(false);

//   // Filtering the options based on the search input
//   const filteredOptions = options.filter(option =>
//     option.toLowerCase().includes(search.toLowerCase())
//   );

//   return (
//     <div className="dropdown">
//       <input
//         type="text"
//         className="dropdown-input"
//         placeholder="Select a name..."
//         value={search}
//         onChange={(e) => setSearch(e.target.value)}
//         onClick={() => setIsOpen(!isOpen)}
//         onBlur={() => setTimeout(() => setIsOpen(false), 100)}
//       />
//       {isOpen && (
//         <ul className="dropdown-list">
//           {filteredOptions.map((option, index) => (
//             <li
//               key={index}
//               className="dropdown-item"
//               onClick={() => {
//                 setSearch(option);
//                 setIsOpen(false);
//                 onSelect(option);//notify parent
//               }}
//             >
//               {option}
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// }

// export default SearchableDropdown;
