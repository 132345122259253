import React, { Component, useEffect, useState, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { AdminContext } from '../components/AdminContext';
//import cycles from '../data/cycles'
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import { GroupContext } from '../components/GroupContext';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet-async';
import Modal from 'react-bootstrap/Modal';
import { format, parseISO } from 'date-fns';
import { Watch } from 'react-loader-spinner';
import SideNav from '../components/SideNav';

const apiUrl = process.env.REACT_APP_API_URL;

function Cycle(props) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const isLastCard = props.cycle === props.cycles[props.cycles.length - 1];

  return (
    <Card
      className={`card h-200 custom-style ${isLastCard ? 'last-card' : ''}`}
    >
      <Card.Body>
        <Row>
          <Col className="col-6 d-flex align-items-center">
            {' '}
            <div className="cyclePeriod   d-flex align-items-center">
              <div className="cycleItem  d-flex align-items-center">
                <h6 className="p-2  d-flex align-items-center">
                  {new Date(props.cycle.startDate).toLocaleDateString(
                    'us-EN',
                    options,
                  )}{' '}
                  -{' '}
                  {new Date(props.cycle.endDate).toLocaleDateString(
                    'us-EN',
                    options,
                  )}
                </h6>{' '}
              </div>
            </div>
          </Col>
          <Col className="col-2 d-flex align-items-center">
            <div className="custom-Button mx-auto">
              {' '}
              <Link to={`review/${props.cycle._id}`}>
                <img
                  src={process.env.PUBLIC_URL + '/img/review-v1.png'}
                  alt="review"
                />
                review
              </Link>{' '}
            </div>
          </Col>
          <Col className="col-2  d-flex align-items-center">
            <div className="custom-Button mx-auto">
              <Link to={`/update/${props.groupId}/${props.cycle._id}`}>
                <img
                  src={process.env.PUBLIC_URL + '/img/edit-v1.png'}
                  alt="update"
                />
                update
              </Link>{' '}
            </div>
          </Col>

          <Col className="col-2  d-flex align-items-center">
            <div className="custom-Button">
              {' '}
              <Link
                onClick={() => {
                  props.deleteCycle(props.cycle._id);
                }}
                to={'#'}
              >
                <img
                  src={process.env.PUBLIC_URL + '/img/delete-v1.png'}
                  alt="delete"
                />
                delete
              </Link>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
const GroupCycleOverview = () => {
  // const { adminId } = useContext(AdminContext);
  const [loading, setLoading] = useState(true);
  const [cycles, setCycles] = useState([]);
  const [groupDetails, setGroupDetails] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  let { groupId } = useParams();
  const { updateGroupId, currentGroupId } = useContext(GroupContext);

  const deleteCycle = async (id) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('No token found, user must login.');
      return;
    }

    // Ask the user to confirm deletion
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this cycle?',
    );
    if (!confirmDelete) {
      return; // If the user clicks "Cancel", stop the function
    }

    try {
      // Proceed with the DELETE request
      await axios.delete(`${apiUrl}/cycles/${id}`, {
        headers: {
          'x-auth-token': token,
        },
      });

      // Update the state to remove the deleted cycle
      setCycles(cycles.filter((cycle) => cycle._id !== id));
    } catch (error) {
      console.error('Failed to delete the cycle:', error);
      alert('Failed to delete the cycle');
    }
  };

  useEffect(() => {
    setLoading(true);
    if (groupId) {
      updateGroupId(groupId);
    }

    const token = localStorage.getItem('token'); //
    const isLoggedIn = Boolean(localStorage.getItem('token'));
    const fetchData = async () => {
      try {
        if (isLoggedIn) {
          // Check if adminId is defined
          try {
            if (groupId && isLoggedIn) {
              // Check if adminId is defined
              const response_gd = await axios.get(
                `${apiUrl}/groups/${groupId}`,
                {
                  headers: {
                    'x-auth-token': token,
                  },
                },
              );

              const group = response_gd.data;

              setGroupDetails(group);
              // setGroupDetails(group);
              console.log(groupDetails);
            } else {
              navigate('/signup');
            }
          } catch (error) {
            console.error(error.message);
          }

          const response = await axios.get(`${apiUrl}/cycles/${groupId}`, {
            headers: {
              'x-auth-token': token,
            },
          });

          setCycles(response.data);
        } else {
          navigate('/signup');
        }
      } catch (error) {
        console.log(error);
        let message = 'An error occurred';
        if (error.response.data.error.includes('Cycle limit')) {
          const maxCycles = error.response.data.error.match(/\d+/)[0];
          message = `Sorry, you've reached the maximum limit of ${maxCycles} cycles you can create. Please upgrade your plan :) `;

          setErrorMessage(message);
          setShowErrorModal(true);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    //console.log("inOverview");
  }, [groupId]);

  const navigate = useNavigate();
  const navigateToCreate = () => {
    navigate(`/mygroups/${groupId}/groupcycle`);
  };
  return (
    <>
      <Row>
        <Col md={2} className="sidebar">
          <SideNav />
        </Col>

        <Col>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-75">
              <div className="loading-section">
                <Watch
                  visible={true}
                  height="40"
                  width="40"
                  radius="48"
                  color="#4fa94d"
                  ariaLabel="watch-loading"
                  wrapperStyle={{}}
                  wrapperClass="watch-load"
                />
                <p className="loading-text">
                  "The two most powerful warriors are patience and time." – Leo
                  Tolstoy
                </p>
              </div>
            </div>
          ) : (
            <div>
              <Helmet>
                <title>Overview </title>
                <meta
                  name="description"
                  content="Asquad - accountability made easy"
                />
              </Helmet>
              <Container>
                <MainLayout title="Overview">
                  <p className="h-gd-title">
                    {groupDetails && groupDetails.groupname}{' '}
                  </p>
                  {cycles.length === 0 ? ( // Check if cycles.length is zero
                    <Row className="d-flex justify-content-center align-items-center ">
                      <div className="d-flex justify-content-center wrapper-empty">
                        <div className="text-empty">
                          {' '}
                          No Cycles found, click below to start adding
                        </div>
                      </div>
                    </Row>
                  ) : (
                    <>
                      {cycles.map((currentCycle) => (
                        <Cycle
                          cycle={currentCycle}
                          deleteCycle={deleteCycle}
                          startDate={currentCycle.startDate}
                          endDate={currentCycle.endDate}
                          key={currentCycle._id}
                          cycles={cycles}
                          groupId={groupId}
                        />
                      ))}
                    </>
                  )}

                  <Row>
                    <Col className="d-flex justify-content-center">
                      <Button
                        className="align-self-center blackBigBtn"
                        onClick={navigateToCreate}
                      >
                        Create cycle
                      </Button>
                    </Col>
                  </Row>
                </MainLayout>
              </Container>
              <Modal
                show={showErrorModal}
                onHide={() => setShowErrorModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowErrorModal(false)}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default GroupCycleOverview;
