import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import React, { Component, useEffect, useState, useContext } from 'react';
import { UserContext } from '../components/UserContext';
import { Watch } from 'react-loader-spinner';
import axios from 'axios';
import { FaUsers } from 'react-icons/fa6';
import { RxArrowRight } from 'react-icons/rx';
import './BrowseGroups.css';
import SideNav from '../components/SideNav';
import BoxGroup from './BoxGroup';

const apiUrl = process.env.REACT_APP_API_URL;

const MyGroups = () => {
  const navigate = useNavigate();

  const { userId } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [userCreatedGroups, setUserCreatedGroups] = useState([]);
  const [userbelongGroups, setUserBelongGroups] = useState([]);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem('token'); //
    const isLoggedIn = Boolean(localStorage.getItem('token'));
    const fetchData = async () => {
      setLoading(true);
      try {
        if (userId && isLoggedIn) {
          // Check if adminId is defined
          const ucgroupsRes = await axios.get(
            `${apiUrl}/users/${userId}/uc-groups`,
            {
              headers: {
                'x-auth-token': token,
              },
            },
          );

          setUserCreatedGroups(ucgroupsRes.data);

          const userbelongGroupsRes = await axios.get(
            `${apiUrl}/users/${userId}/my-groups`,
            {
              headers: {
                'x-auth-token': token,
              },
            },
          );

          // Filter out groups where the current user is the creator
          const filteredBelongGroups = userbelongGroupsRes.data.filter(
            (group) =>
              !ucgroupsRes.data.find(
                (createdGroup) => createdGroup._id === group._id,
              ),
          );

          setUserBelongGroups(filteredBelongGroups);
        } else {
          navigate('/signup');
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  return (
    <>
      <Row>
        <Col md={2} className="sidebar">
          <SideNav />
        </Col>
        <Col>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-75">
              <div className="loading-section">
                <Watch
                  visible={true}
                  height="40"
                  width="40"
                  radius="48"
                  color="#4fa94d"
                  ariaLabel="watch-loading"
                  wrapperStyle={{}}
                  wrapperClass="watch-load"
                />
                <p className="loading-text">
                  "The two most powerful warriors are patience and time." – Leo
                  Tolstoy
                </p>
              </div>
            </div>
          ) : (
            <div className="content-section">
              <Row>
                <Col>
                  <h3 className="page-title-browse">My Groups</h3>
                </Col>
              </Row>

              <Row>
                <Row>
                  <Col>
                    <h3 className="page-title-browse-black">Organizer</h3>
                  </Col>
                </Row>
                {userCreatedGroups.length > 0 ? (
                  userCreatedGroups.map((group, key) => {
                    return (
                      <Col
                        key={group._id}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        className="box-section"
                      >
                        <BoxGroup
                          groupname={group.groupname}
                          description={group.description}
                          numberOfCycles={group?.TotalNoOfCycles}
                          groupId={group._id}
                          numberOfMembers={group.TotalNoOfUsers}
                        />
                      </Col>
                    );
                  })
                ) : (
                  <div className="d-flex justify-content-center">
                    <p> No organized groups </p>
                  </div>
                )}
              </Row>
              <Row className="memberRow">
                <Row>
                  <Col>
                    <h3 className="page-title-browse-black">Member</h3>
                  </Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
                {userbelongGroups.length > 0 ? (
                  userbelongGroups.map((group, key) => {
                    return (
                      <Col
                        key={group._id}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        className="box-section"
                      >
                        <BoxGroup
                          groupname={group.groupname}
                          description={group.description}
                          numberOfCycles={3}
                          groupId={group._id}
                          numberOfMembers={group.TotalNoOfUsers}
                        />
                      </Col>
                    );
                  })
                ) : (
                  <div className="d-flex justify-content-center">
                    <p>No groups been added </p>
                  </div>
                )}
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default MyGroups;
