import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

// Load your Stripe public key
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripePublicKey);

const PaymentModalContent = ({ onHide, onSuccess, selectedPlan }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    const token = localStorage.getItem('token'); //
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);
    const userId = localStorage.getItem('userId');
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      // console.log('Error:', error);
      setIsLoading(false);
    } else {
      axios
        .post(
          `${apiUrl}/stripe/subscribe`,
          {
            paymentMethodId: paymentMethod.id,
            planId: selectedPlan,
            userId: userId,
          },

          {
            headers: {
              'x-auth-token': token,
            },
          },
        )
        .then((response) => {
          // console.log('Subscription successful:', response.data);
          onSuccess(); // Close the modal on success
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Subscription error:', error);
          setIsLoading(false);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <CardElement />
      <Button type="submit" className="mt-3" disabled={!stripe}>
        {isLoading ? 'processing' : 'Subscribe'}
      </Button>
    </Form>
  );
};

const PaymentModal = ({ show, onHide, onSuccess, selectedPlan }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Enter Payment Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Elements stripe={stripePromise}>
          <PaymentModalContent
            onSuccess={onSuccess}
            onHide={onHide}
            selectedPlan={selectedPlan}
          />
        </Elements>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModal;
