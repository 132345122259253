import React, { useState, useEffect } from 'react';
import { Button, Card, InputGroup, Form } from 'react-bootstrap';

const TaskManager = ({ onChecklistUpdate }) => {
  const [taskChecklist, setTaskChecklist] = useState([
    {
      taskName: 'Main Task 1',
      subtasks: [
        { subTaskName: 'Subtask 1.1', frequency: 'one-off' },
        { subTaskName: 'Subtask 1.2', frequency: 'daily' },
      ],
    },
  ]);

  useEffect(() => {
    // Send the updated task checklist to the parent component
    onChecklistUpdate(taskChecklist);
  }, [taskChecklist, onChecklistUpdate]);

  const addTask = () => {
    setTaskChecklist((prev) => [...prev, { taskName: '', subtasks: [] }]);
  };

  const addSubtask = (taskIndex) => {
    setTaskChecklist((prev) => {
      const updatedChecklist = prev.map((task, index) =>
        index === taskIndex
          ? {
              ...task,
              subtasks: [
                ...task.subtasks,
                { subTaskName: '', frequency: 'one-off' },
              ],
            }
          : task,
      );
      return updatedChecklist;
    });
  };

  // Function to delete a subtask
  const deleteSubtask = (taskIndex, subtaskIndex) => {
    setTaskChecklist((prev) => {
      const updatedChecklist = prev.map((task, index) =>
        index === taskIndex
          ? {
              ...task,
              subtasks: task.subtasks.filter(
                (_, sIndex) => sIndex !== subtaskIndex,
              ),
            }
          : task,
      );
      return updatedChecklist;
    });
  };

  const updateTaskName = (taskIndex, taskName) => {
    setTaskChecklist((prev) => {
      const updatedChecklist = prev.map((task, index) =>
        index === taskIndex ? { ...task, taskName } : task,
      );
      return updatedChecklist;
    });
  };

  const updateSubtaskName = (taskIndex, subtaskIndex, subTaskName) => {
    setTaskChecklist((prev) => {
      const updatedChecklist = prev.map((task, index) =>
        index === taskIndex
          ? {
              ...task,
              subtasks: task.subtasks.map((subtask, sIndex) =>
                sIndex === subtaskIndex ? { ...subtask, subTaskName } : subtask,
              ),
            }
          : task,
      );
      return updatedChecklist;
    });
  };

  // Function to delete a main task
  const deleteTask = (taskIndex) => {
    setTaskChecklist((prev) => prev.filter((_, index) => index !== taskIndex));
  };
  const updateSubtaskFrequency = (taskIndex, subtaskIndex, frequency) => {
    setTaskChecklist((prev) => {
      const updatedChecklist = prev.map((task, index) =>
        index === taskIndex
          ? {
              ...task,
              subtasks: task.subtasks.map((subtask, sIndex) =>
                sIndex === subtaskIndex ? { ...subtask, frequency } : subtask,
              ),
            }
          : task,
      );
      return updatedChecklist;
    });
  };

  return (
    <Card className="mb-3 shared-box">
      <Card.Body>
        <Card.Title className="shared-title"> Shared Checklist</Card.Title>

        <div className="add-goal-btn" onClick={addTask}>
          Add Goal
        </div>

        {taskChecklist.map((task, taskIndex) => (
          <div key={taskIndex} className="mt-3">
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                value={task.taskName}
                placeholder="Task Name"
                onChange={(e) => updateTaskName(taskIndex, e.target.value)}
                required
              />

              <div
                className="delete-task"
                onClick={() => deleteTask(taskIndex)}
                style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }} // Styling for delete button
              >
                X
              </div>
            </InputGroup>

            <div
              className="add-sbtask-btn"
              onClick={() => addSubtask(taskIndex)}
            >
              Add Subtask
            </div>

            {task.subtasks.map((subtask, subtaskIndex) => (
              <InputGroup className="mt-2" key={subtaskIndex}>
                <Form.Control
                  type="text"
                  value={subtask.subTaskName}
                  placeholder="Subtask Name"
                  onChange={(e) =>
                    updateSubtaskName(taskIndex, subtaskIndex, e.target.value)
                  }
                  required
                />
                {/* <Form.Check
                  type="checkbox"
                  checked={subtask.frequency === 'daily'}
                  onChange={(e) =>
                    updateSubtaskFrequency(
                      taskIndex,
                      subtaskIndex,
                      e.target.checked ? 'daily' : 'one-off',
                    )
                  }
                  label="Daily Task"
                /> */}
                <div
                  className="delete-subtask"
                  onClick={() => deleteSubtask(taskIndex, subtaskIndex)}
                >
                  X
                </div>
              </InputGroup>
            ))}
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

export default TaskManager;
